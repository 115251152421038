import * as React from "react";
import NavBar from "../../components/navbar";
import MainUseCasesComponent from "../../components/usecases";
import FooterPage from "../footer";

const UseCasesPage = () => {
  return <div>
    <NavBar />
    <MainUseCasesComponent />
    <FooterPage />
  </div>

}

export default UseCasesPage;
