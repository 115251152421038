import React from "react";

function TodoListIcon({width, height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="aa148906-9946-4a00-bfdb-f78e96a2b6cd"
      width={width}
      height={height}
      data-name="Layer 1"
      viewBox="0 0 848.675 469.443"
    >
      <path
        fill="#f2f2f2"
        d="M612.366 682.319a207.534 207.534 0 01-40.54 1.96c-32.66-1.65-64.78-11.15-92.23-29.01-12.17-7.92-23.78-17.65-30.08-30.72-8.55-17.75-5.56-40.22 6.65-55.68a56.015 56.015 0 0116.14-13.77c.92-.52 1.86-1.02 2.81-1.5a60.557 60.557 0 0133.48-6.13c19.58 2.14 37.65 14.1 48.09 30.81 9.3 14.9 12.54 32.48 14.45 50.1.36 3.32.67 6.65.97 9.96a146.38 146.38 0 01-7.81 28.06 9.811 9.811 0 00-.44 1.36q3 6.51 5.71 13.14a11.557 11.557 0 005.53 1.4h37.19z"
        transform="translate(-175.662 -215.279)"
      ></path>
      <path
        fill="#fff"
        d="M568.795 683.169a2.033 2.033 0 01-2.7-1.06c-.16-.38-.31-.76-.47-1.14q-2.16-5.25-4.53-10.4a316.784 316.784 0 00-88.17-114.1 1.478 1.478 0 01-.62-1.37 2.032 2.032 0 012.81-1.5 1.927 1.927 0 01.42.26 317.52 317.52 0 0144.26 43.95 322.504 322.504 0 0134.91 51.66q4.065 7.5 7.72 15.21c.48 1.02.96 2.05 1.43 3.08q3 6.51 5.71 13.14c.03.07.06.15.09.22a1.48 1.48 0 01-.86 2.05z"
        transform="translate(-175.662 -215.279)"
      ></path>
      <circle cx="260.503" cy="297" r="39" fill="#00b775"></circle>
      <path
        fill="#fff"
        d="M432.3 527.156a4.045 4.045 0 01-2.434-.808l-.044-.033-9.165-7.017a4.072 4.072 0 114.953-6.465l5.937 4.553 14.029-18.296a4.072 4.072 0 015.709-.754l-.088.118.09-.116a4.076 4.076 0 01.754 5.709l-16.502 21.52a4.074 4.074 0 01-3.24 1.59z"
        transform="translate(-175.662 -215.279)"
      ></path>
      <path
        fill="#3f3d56"
        d="M1023.147 684.721H176.853a1.19 1.19 0 010-2.381h846.294a1.19 1.19 0 010 2.381z"
        transform="translate(-175.662 -215.279)"
      ></path>
      <circle cx="165.546" cy="107.005" r="28.94" fill="#2f2e41"></circle>
      <ellipse
        cx="312.268"
        cy="300.329"
        fill="#2f2e41"
        rx="11.975"
        ry="8.981"
        transform="rotate(-45 -35.427 404.733)"
      ></ellipse>
      <ellipse
        cx="360.359"
        cy="292.785"
        fill="#2f2e41"
        rx="8.981"
        ry="11.975"
        transform="rotate(-66.87 109.5 318.173)"
      ></ellipse>
      <path
        fill="#ffb8b8"
        d="M421.794 489.366a10.743 10.743 0 00-6.253-15.24l-41.816-88.46-19.128 13.414 47.119 82.735a10.8 10.8 0 0020.078 7.55z"
        transform="translate(-175.662 -215.279)"
      ></path>
      <path
        fill="#ccc"
        d="M383.314 400.425l-22.208 9.93a4.817 4.817 0 01-6.604-3.095l-6.55-23.354a13.377 13.377 0 0124.456-10.85l13.002 20.383a4.817 4.817 0 01-2.096 6.986z"
        transform="translate(-175.662 -215.279)"
      ></path>
      <path
        fill="#ffb8b8"
        d="M280.747 498.136a10.743 10.743 0 007.444-14.695l39.603-89.472-22.655-5.706-31.804 89.743a10.8 10.8 0 007.412 20.13z"
        transform="translate(-175.662 -215.279)"
      ></path>
      <path
        fill="#ffb8b8"
        d="M94.289 445.033L105.809 449.227 127.47 406.789 110.467 400.599 94.289 445.033z"
      ></path>
      <path
        fill="#2f2e41"
        d="M264.972 662.263h38.53v14.887H279.86a14.887 14.887 0 01-14.887-14.887z"
        transform="rotate(-159.993 177.42 577.56)"
      ></path>
      <path
        fill="#2f2e41"
        d="M297.2 635.89a4.774 4.774 0 01-2.044-.46l-13.24-6.27a4.799 4.799 0 01-2.468-5.99l37.364-103.468a4.817 4.817 0 018.66-.841l19.763 32.937a4.822 4.822 0 01.052 4.868L301.4 633.468a4.84 4.84 0 01-4.2 2.422z"
        transform="translate(-175.662 -215.279)"
      ></path>
      <circle cx="166.464" cy="113.913" r="24.561" fill="#ffb8b8"></circle>
      <path
        fill="#ccc"
        d="M376.873 440.862l-43.488-11.96-23.13-46.259a14.576 14.576 0 0113.038-21.094h34.6a14.557 14.557 0 019.73 3.721c9.28 8.315 28.78 32.285 9.438 75.174z"
        transform="translate(-175.662 -215.279)"
      ></path>
      <path
        fill="#ccc"
        d="M322.433 409.97l-22.034-10.313a4.817 4.817 0 01-1.974-7.021l13.39-20.223a13.377 13.377 0 0124.199 11.413l-6.925 23.163a4.817 4.817 0 01-6.656 2.982z"
        transform="translate(-175.662 -215.279)"
      ></path>
      <path
        fill="#ffb8b8"
        d="M173.428 456.629L185.687 456.629 191.52 409.341 173.425 409.342 173.428 456.629z"
      ></path>
      <path
        fill="#2f2e41"
        d="M346.463 668.404h38.53v14.887H361.35a14.887 14.887 0 01-14.887-14.887z"
        transform="rotate(179.997 277.9 568.207)"
      ></path>
      <path
        fill="#2f2e41"
        d="M353.72 653.888a4.791 4.791 0 01-4.699-3.814c-3.524-16.604-21.826-101.652-36.11-145.46-14.535-44.57 10.218-64.87 15.327-68.488a3.763 3.763 0 001.253-1.512l3.712-8.166a4.834 4.834 0 014.397-2.827h.03c27.829.166 37.184 8.939 39.915 12.703a4.761 4.761 0 01.753 3.926l-17.607 72.645a3.75 3.75 0 00-.073 1.377l17.518 131.9a4.818 4.818 0 01-4.223 5.42l-19.628 2.264a4.923 4.923 0 01-.566.032zM316.318 313.743a33.405 33.405 0 0019.09 5.9 20.47 20.47 0 01-8.113 3.338 67.359 67.359 0 0027.514.155 17.807 17.807 0 005.76-1.979 7.29 7.29 0 003.555-4.754c.604-3.449-2.083-6.582-4.876-8.693a35.967 35.967 0 00-30.224-6.04c-3.377.873-6.759 2.347-8.952 5.059s-2.842 6.891-.753 9.683z"
        transform="translate(-175.662 -215.279)"
      ></path>
      <circle cx="591.503" cy="192" r="13" fill="#00b775"></circle>
      <path
        fill="#fff"
        d="M765.877 412.238a1.348 1.348 0 01-.811-.27l-.015-.01-3.055-2.34a1.357 1.357 0 111.65-2.154l1.98 1.517 4.676-6.099a1.357 1.357 0 011.903-.25l-.029.039.03-.04a1.359 1.359 0 01.251 1.904l-5.5 7.173a1.358 1.358 0 01-1.08.53z"
        transform="translate(-175.662 -215.279)"
      ></path>
      <circle cx="591.503" cy="230" r="13" fill="#00b775"></circle>
      <path
        fill="#fff"
        d="M765.877 450.238a1.348 1.348 0 01-.811-.27l-.015-.01-3.055-2.34a1.357 1.357 0 111.65-2.154l1.98 1.517 4.676-6.099a1.357 1.357 0 011.903-.25l-.029.039.03-.04a1.359 1.359 0 01.251 1.904l-5.5 7.173a1.358 1.358 0 01-1.08.53z"
        transform="translate(-175.662 -215.279)"
      ></path>
      <circle cx="598.503" cy="274" r="13" fill="#00b775"></circle>
      <path
        fill="#fff"
        d="M772.877 494.238a1.348 1.348 0 01-.811-.27l-.015-.01-3.055-2.34a1.357 1.357 0 111.65-2.154l1.98 1.517 4.676-6.099a1.357 1.357 0 011.903-.25l-.029.039.03-.04a1.359 1.359 0 01.251 1.904l-5.5 7.173a1.358 1.358 0 01-1.08.53z"
        transform="translate(-175.662 -215.279)"
      ></path>
      <path
        fill="#e6e6e6"
        d="M899.521 597.818c-.134 0-.22-.003-.249-.005l-16.14.002v-2.38h16.198c.375.013 8.027.23 15.077-8.325 10.506-12.749 19.133-44.31.573-122.264-24.957-104.82-4.142-197.424 5.966-232.372a11.604 11.604 0 00-11.165-14.816H668.747a11.656 11.656 0 00-9.4 4.785c-4.281 5.89-10.157 17.205-13.834 37.665l-2.342-.42c3.756-20.905 9.824-32.553 14.252-38.644a14.041 14.041 0 0111.324-5.765h241.034a13.984 13.984 0 0113.45 17.856c-10.055 34.767-30.762 126.89-5.936 231.16 18.822 79.05 9.773 111.295-1.131 124.425-7.193 8.66-15.118 9.098-16.643 9.098z"
        transform="translate(-175.662 -215.279)"
      ></path>
      <path
        fill="#ccc"
        d="M856.092 641.248c-.135 0-.22-.004-.25-.006l-16.139.002v-2.38h16.198c.377.012 8.027.23 15.076-8.324 10.506-12.75 19.134-44.31.573-122.264-24.957-104.821-4.141-197.424 5.966-232.373a11.604 11.604 0 00-11.164-14.815H625.317a11.656 11.656 0 00-9.399 4.784c-4.282 5.89-10.158 17.205-13.835 37.665l-2.342-.42c3.757-20.905 9.824-32.553 14.252-38.644a14.041 14.041 0 0111.324-5.765h241.035a13.984 13.984 0 0113.45 17.857c-10.055 34.767-30.763 126.89-5.936 231.16 18.821 79.05 9.773 111.295-1.132 124.424-7.192 8.66-15.117 9.099-16.642 9.099z"
        transform="translate(-175.662 -215.279)"
      ></path>
      <path
        fill="#3f3d56"
        d="M830.435 551.159c-24.83-104.27-4.12-196.4 5.94-231.16a13.994 13.994 0 00-13.45-17.86h-241.04a14.031 14.031 0 00-11.32 5.76c-9.73 13.38-29.92 57.75-7.37 180.52 12.06 65.68 11.88 110.46 7.94 139.96-2.21 16.61-5.62 28.37-8.71 36.3l-.33.84a13.64 13.64 0 00-1 5.05 13.834 13.834 0 004.53 10.4 13.243 13.243 0 003.17 2.2 13.412 13.412 0 003.03 1.11 13.676 13.676 0 003.27.39h237.32c.03 0 .11.01.25.01 1.52 0 9.45-.44 16.64-9.1 10.9-13.13 19.95-45.37 1.13-124.42zm-2.89 122.81c-7.05 8.56-14.7 8.34-15.07 8.33h-237.38a11.636 11.636 0 01-9.63-5.11 11.367 11.367 0 01-1.61-9.43 9.811 9.811 0 01.44-1.36 146.38 146.38 0 007.81-28.06c5.64-30.75 6.69-78.2-6.57-150.35-22.38-121.86-2.59-165.56 6.95-178.69a11.681 11.681 0 019.4-4.78h241.04a11.604 11.604 0 0111.16 14.81c-10.11 34.95-30.92 127.56-5.96 232.38 18.56 77.95 9.93 109.51-.58 122.26z"
        transform="translate(-175.662 -215.279)"
      ></path>
      <path
        fill="#ccc"
        d="M715.925 415.679H591.739a9.519 9.519 0 110-19.038h124.186a9.519 9.519 0 110 19.038zM715.925 456.679H591.739a9.519 9.519 0 110-19.038h124.186a9.519 9.519 0 110 19.038zM725.925 498.679H601.739a9.519 9.519 0 110-19.038h124.186a9.519 9.519 0 110 19.038zM732.925 542.679H608.739a9.519 9.519 0 110-19.038h124.186a9.519 9.519 0 110 19.038z"
        transform="translate(-175.662 -215.279)"
      ></path>
      <path
        fill="#00b775"
        d="M732.977 341.77H643.74a9.519 9.519 0 110-19.038h89.238a9.519 9.519 0 110 19.037z"
        transform="translate(-175.662 -215.279)"
      ></path>
      <circle cx="606.503" cy="317.882" r="13" fill="#ccc"></circle>
      <path
        fill="#ccc"
        d="M738.925 585.679H614.739a9.519 9.519 0 110-19.038h124.186a9.519 9.519 0 110 19.038z"
        transform="translate(-175.662 -215.279)"
      ></path>
      <circle cx="612.503" cy="360.882" r="13" fill="#ccc"></circle>
    </svg>
  );
}

export default React.memo(TodoListIcon);
