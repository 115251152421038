import React from "react";

function AppWireframeIcon({width, height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="a5b39606-6275-4e50-bae6-002d29ca6512"
      width={width}
      height={height}
      data-name="Layer 1"
      viewBox="0 0 768 728.858"
    >
      <path
        fill="#e6e6e6"
        d="M682.884 258.518h-3.999V148.973a63.402 63.402 0 00-63.402-63.402H383.397a63.402 63.402 0 00-63.402 63.402v600.974a63.402 63.402 0 0063.401 63.402h232.087a63.402 63.402 0 0063.402-63.402V336.494h3.999z"
        transform="translate(-216 -85.571)"
      ></path>
      <path
        fill="#fff"
        d="M618.041 102.066h-30.295a22.495 22.495 0 01-20.827 30.99H433.96a22.495 22.495 0 01-20.826-30.99h-28.296a47.348 47.348 0 00-47.348 47.348v600.092a47.348 47.348 0 0047.348 47.348h233.204a47.348 47.348 0 0047.348-47.348V149.414a47.348 47.348 0 00-47.348-47.348z"
        transform="translate(-216 -85.571)"
      ></path>
      <path
        fill="#3f3d56"
        d="M983 814.429H217a1 1 0 010-2h766a1 1 0 010 2zM642.82 268.075H359.946a5.245 5.245 0 01-5.238-5.239v-70.169a5.245 5.245 0 015.238-5.238H642.82a5.245 5.245 0 015.239 5.238v70.17a5.245 5.245 0 01-5.239 5.238zm-282.874-78.55a3.146 3.146 0 00-3.143 3.142v70.17a3.146 3.146 0 003.143 3.142H642.82a3.146 3.146 0 003.143-3.143v-70.169a3.146 3.146 0 00-3.143-3.143zM642.82 545.71H359.946a5.245 5.245 0 01-5.238-5.239V410.585a5.245 5.245 0 015.238-5.239H642.82a5.245 5.245 0 015.239 5.239V540.47a5.245 5.245 0 01-5.239 5.239zM359.946 407.442a3.146 3.146 0 00-3.143 3.143V540.47a3.146 3.146 0 003.143 3.144H642.82a3.146 3.146 0 003.143-3.144V410.585a3.146 3.146 0 00-3.143-3.143z"
        transform="translate(-216 -85.571)"
      ></path>
      <path
        fill="#e6e6e6"
        d="M640.725 377.033H515.003a5.245 5.245 0 01-5.239-5.238v-70.169a5.245 5.245 0 015.239-5.238h125.722a5.245 5.245 0 015.238 5.238v70.17a5.245 5.245 0 01-5.238 5.237zm-125.722-78.55a3.146 3.146 0 00-3.143 3.143v70.169a3.146 3.146 0 003.143 3.143h125.722a3.146 3.146 0 003.143-3.143v-70.169a3.146 3.146 0 00-3.143-3.143zM485.668 715.434H359.946a5.245 5.245 0 01-5.238-5.239V579.261a5.245 5.245 0 015.238-5.238h125.722a5.245 5.245 0 015.239 5.238v130.935a5.245 5.245 0 01-5.239 5.238zM359.946 576.118a3.146 3.146 0 00-3.143 3.143v130.935a3.146 3.146 0 003.143 3.143h125.722a3.146 3.146 0 003.143-3.143V579.26a3.146 3.146 0 00-3.143-3.143zM640.725 715.434H515.003a5.245 5.245 0 01-5.239-5.239V579.261a5.245 5.245 0 015.239-5.238h125.722a5.245 5.245 0 015.238 5.238v130.935a5.245 5.245 0 01-5.238 5.238zM515.003 576.118a3.146 3.146 0 00-3.143 3.143v130.935a3.146 3.146 0 003.143 3.143h125.722a3.146 3.146 0 003.143-3.143V579.26a3.146 3.146 0 00-3.143-3.143z"
        transform="translate(-216 -85.571)"
      ></path>
      <circle cx="185.804" cy="365.787" r="21.338" fill="#00b775"></circle>
      <path
        fill="#00b775"
        d="M451.085 437.133a3.556 3.556 0 000 7.112h167.66a3.556 3.556 0 000-7.112zM451.085 458.471a3.556 3.556 0 00-.015 7.113h72.16a3.556 3.556 0 100-7.113z"
        transform="translate(-216 -85.571)"
      ></path>
      <path
        fill="#ccc"
        d="M385.437 492.585c-2.706 0-4.907 1.596-4.907 3.557s2.201 3.556 4.907 3.556h231.318c2.705 0 4.907-1.596 4.907-3.556s-2.202-3.557-4.907-3.557zM385.437 513.924c-2.706 0-4.907 1.595-4.907 3.556s2.201 3.556 4.907 3.556h231.318c2.705 0 4.907-1.595 4.907-3.556s-2.202-3.556-4.907-3.556z"
        transform="translate(-216 -85.571)"
      ></path>
      <circle cx="187.899" cy="139.488" r="21.338" fill="#00b775"></circle>
      <path
        fill="#00b775"
        d="M453.18 210.834a3.556 3.556 0 000 7.113h167.66a3.556 3.556 0 10.015-7.113h-.015zM453.18 232.172a3.556 3.556 0 000 7.113h72.145a3.556 3.556 0 10.015-7.113h-.015z"
        transform="translate(-216 -85.571)"
      ></path>
      <path
        fill="#e6e6e6"
        d="M485.557 377.046H367.619l27.755-48.073a5.668 5.668 0 019.816 0l11.857 20.536 20.16-34.918a7.096 7.096 0 0112.29 0zm-114.31-2.095h110.681l-34.245-59.313a5 5 0 00-8.66 0L417.046 353.7l-13.671-23.68a3.572 3.572 0 00-6.187 0zM636.423 377.046H518.485l27.754-48.073a5.668 5.668 0 019.817 0l11.857 20.536 20.16-34.918a7.096 7.096 0 0112.29 0zm-114.31-2.095h110.68l-34.244-59.313a5 5 0 00-8.66 0L567.912 353.7l-13.672-23.68a3.572 3.572 0 00-6.186 0z"
        transform="translate(-216 -85.571)"
      ></path>
      <path
        fill="#e6e6e6"
        d="M485.668 377.033H359.946a5.245 5.245 0 01-5.238-5.238v-70.169a5.245 5.245 0 015.238-5.238h125.722a5.245 5.245 0 015.239 5.238v70.17a5.245 5.245 0 01-5.239 5.237zm-125.722-78.55a3.146 3.146 0 00-3.143 3.143v70.169a3.146 3.146 0 003.143 3.143h125.722a3.146 3.146 0 003.143-3.143v-70.169a3.146 3.146 0 00-3.143-3.143zM386.735 601.024a3.556 3.556 0 100 7.113h72.144a3.556 3.556 0 000-7.113zM386.735 621.977a3.556 3.556 0 100 7.113h72.144a3.556 3.556 0 000-7.113zM422.807 688.433a22.386 22.386 0 1122.386-22.386 22.411 22.411 0 01-22.386 22.386zm0-42.677a20.29 20.29 0 1020.291 20.29 20.314 20.314 0 00-20.29-20.29zM613.936 688.433a3.556 3.556 0 100-7.113h-72.144a3.556 3.556 0 000 7.113zM613.936 667.48a3.556 3.556 0 100-7.114h-72.144a3.556 3.556 0 000 7.113zM577.864 601.024a22.386 22.386 0 11-22.386 22.386 22.411 22.411 0 0122.386-22.386zm0 42.676a20.29 20.29 0 10-20.291-20.29 20.314 20.314 0 0020.29 20.29z"
        transform="translate(-216 -85.571)"
      ></path>
      <path
        fill="#ffb8b8"
        d="M589.987 713.726L573.8 713.726 566.1 651.292 589.99 651.293 589.987 713.726z"
      ></path>
      <path
        fill="#2f2e41"
        d="M557.47 709.1h35.985v19.656h-50.872v-4.769a14.887 14.887 0 0114.887-14.886z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M654.681 713.726L638.495 713.726 630.794 651.292 654.684 651.293 654.681 713.726z"
      ></path>
      <path
        fill="#2f2e41"
        d="M622.165 709.1h35.985v19.656h-50.872v-4.769a14.887 14.887 0 0114.887-14.886z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M777.786 619.314a12.38 12.38 0 00-1.067-18.954l6.935-27.428-15.64-8.28-9.25 38.872a12.447 12.447 0 0019.022 15.79z"
        transform="translate(-216 -85.571)"
      ></path>
      <path
        fill="#2f2e41"
        d="M660.438 485.836L659.118 578.257 657.797 699.724 628.751 697.083 614.228 529.406 595.744 697.083 565.756 697.083 562.736 528.086 573.299 491.117 660.438 485.836z"
      ></path>
      <path
        fill="#3f3d56"
        d="M786.403 577.252l-.425-.191 16.554-113.228-8.415-34.223a7.49 7.49 0 014.641-8.799l15.142-5.677 5.35-14.712 42.206 4.548 1.038 11.498c4.574 1.467 42.237 14.01 39.638 28.302-2.565 14.107-17.709 58.488-18.478 60.738l-2.652 63.649-.326.163c-.393.196-29.485 14.5-61.089 14.498-11.13 0-22.577-1.776-33.184-6.566z"
        transform="translate(-216 -85.571)"
      ></path>
      <path
        fill="#3f3d56"
        d="M758.217 589.137l13.307-85.161c.079-.502 7.952-50.355 10.563-66.02 2.696-16.173 15.215-16.36 15.342-16.36h6.89l8.058 36.265-15.876 72.763-14.647 60.298z"
        transform="translate(-216 -85.571)"
      ></path>
      <path
        fill="#ffb8b8"
        d="M844.529 580.732a12.38 12.38 0 0115.115-11.485l16.372-23.073 16.448 6.53-23.578 32.258a12.447 12.447 0 01-24.357-4.23z"
        transform="translate(-216 -85.571)"
      ></path>
      <path
        fill="#3f3d56"
        d="M862.489 560.89l29.109-48.957-11.86-31.626 16.153-43.074.595.256c.39.167 9.569 4.263 10.929 18.93l18.562 58.336-49.54 64.267z"
        transform="translate(-216 -85.571)"
      ></path>
      <circle
        cx="838.699"
        cy="362.753"
        r="30.389"
        fill="#ffb8b8"
        transform="rotate(-61.337 658.55 502.09)"
      ></circle>
      <path
        fill="#2f2e41"
        d="M818.296 347.951c3.146 3.723 8.47 4.774 13.38 5.204 9.974.875 24.477-.544 34.224-2.794.695 6.742-1.202 14.012 2.77 19.559a90.798 90.798 0 003.226-20.584c.12-2.986.073-6.075-1.14-8.816s-3.86-5.068-6.907-5.092a17.311 17.311 0 017.721-3.301l-9.649-4.85 2.478-2.543-17.469-1.07 5.06-3.214a109.715 109.715 0 00-22.986-.725c-3.56.262-7.282.763-10.166 2.826s-4.551 6.162-2.76 9.19a13.344 13.344 0 00-10.17 8.069c-1.286 3.26-1.168 6.88-.772 10.355a73.399 73.399 0 003.849 16.28"
        transform="translate(-216 -85.571)"
      ></path>
    </svg>
  );
}

export default React.memo(AppWireframeIcon);
