import React from "react";

function RestaurantIcon({width, height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="bd9ea3a9-b4c9-40eb-b9f6-70e25d8cad06"
      width={width}
      height={height}
      data-name="Layer 1"
      viewBox="0 0 767.457 518.945"
    >
      <ellipse
        cx="645.091"
        cy="509.733"
        fill="#e6e6e6"
        rx="122.366"
        ry="9.212"
      ></ellipse>
      <path
        fill="#3f3d56"
        d="M340.91 333.454c.039-9.376-7.95-17.045-17.325-17.045H236.58c-9.227 0-17.2 7.543-17.308 16.77a16.936 16.936 0 006.57 13.61 3.789 3.789 0 011.5 3.468 16.853 16.853 0 00-.15 2.256v60.18a16.868 16.868 0 0016.867 16.868h71.495a16.868 16.868 0 0016.868-16.868v-60.18a16.957 16.957 0 00-.115-1.976 3.66 3.66 0 011.54-3.378 16.946 16.946 0 007.061-13.705z"
        transform="translate(-216.272 -190.527)"
      ></path>
      <path
        fill="#00b775"
        d="M337.142 331.803a13.495 13.495 0 00-13.505-11.716h-87.081a13.528 13.528 0 00-13.301 10.477 13.317 13.317 0 006.364 14.342 3.663 3.663 0 011.715 4.147 13.059 13.059 0 00-.464 3.463v60.174a13.198 13.198 0 0010.75 12.967 13.358 13.358 0 002.443.226h71.495a13.046 13.046 0 003.57-.492 13.191 13.191 0 009.618-12.696V352.51a13.132 13.132 0 00-.408-3.247 3.768 3.768 0 01-.113-.9 3.668 3.668 0 011.946-3.236l.006-.006a13.29 13.29 0 006.965-13.318z"
        transform="translate(-216.272 -190.527)"
      ></path>
      <path
        d="M328.746 358.275v-5.657h-9.618v-32.531h-5.658v32.53h-30.268v-32.53h-5.657v32.53h-30.268v-32.53h-5.658v32.53h-10.75v5.658h10.75v16.973h-10.75v5.658h10.75v16.972h-10.75v5.658h10.75v22.121a13.358 13.358 0 002.444.226h3.214v-22.347h30.268v22.347h5.657v-22.347h30.268v22.347h2.088a13.046 13.046 0 003.57-.492v-21.855h9.618v-5.658h-9.618v-16.972h9.618v-5.658h-9.618v-16.973zm-81.47 0h30.269v16.973h-30.268zm0 39.603v-16.972h30.269v16.972zm66.194 0h-30.268v-16.972h30.268zm0-22.63h-30.268v-16.973h30.268z"
        opacity="0.2"
        transform="translate(-216.272 -190.527)"
      ></path>
      <path
        fill="#00b775"
        d="M340.513 190.527a60.422 60.422 0 11-120.845 0h120.845z"
        transform="translate(-216.272 -190.527)"
      ></path>
      <path
        fill="#3f3d56"
        d="M241.835 201.253c0 1.58-.56 2.86-1.251 2.86s-1.252-1.28-1.252-2.86a4.662 4.662 0 011.252-2.86 4.661 4.661 0 011.251 2.86zM305.118 211.264c0 1.58-.56 2.86-1.252 2.86s-1.251-1.28-1.251-2.86a4.662 4.662 0 011.251-2.86 4.662 4.662 0 011.252 2.86zM258.639 205.544c0 1.58-.56 2.86-1.252 2.86s-1.25-1.28-1.25-2.86a4.662 4.662 0 011.25-2.86 4.662 4.662 0 011.252 2.86zM283.666 228.068c0 1.58-.56 2.86-1.252 2.86s-1.25-1.28-1.25-2.86a4.662 4.662 0 011.25-2.86 4.662 4.662 0 011.252 2.86zM256.851 225.208c0 1.58-.56 2.86-1.251 2.86s-1.252-1.28-1.252-2.86a4.661 4.661 0 011.252-2.86 4.662 4.662 0 011.251 2.86zM319.419 198.393c0 1.58-.56 2.86-1.252 2.86s-1.251-1.28-1.251-2.86a4.662 4.662 0 011.251-2.86 4.662 4.662 0 011.252 2.86zM296.537 233.788c0 1.58-.56 2.86-1.251 2.86s-1.252-1.28-1.252-2.86a4.662 4.662 0 011.252-2.86 4.662 4.662 0 011.25 2.86z"
        transform="translate(-216.272 -190.527)"
      ></path>
      <path
        fill="#3f3d56"
        d="M280.09 254.346a63.891 63.891 0 01-63.818-63.819h6.793a57.026 57.026 0 10114.051 0h6.793a63.891 63.891 0 01-63.818 63.82z"
        transform="translate(-216.272 -190.527)"
      ></path>
      <path fill="#3f3d56" d="M190.819 21.909H210.819V41.909H190.819z"></path>
      <path
        fill="#3f3d56"
        d="M245.819 30.909H406.81899999999996V32.909H245.819z"
      ></path>
      <path fill="#3f3d56" d="M190.819 172.458H210.819V192.458H190.819z"></path>
      <path
        fill="#3f3d56"
        d="M245.819 181.458H406.81899999999996V183.458H245.819z"
      ></path>
      <path fill="#3f3d56" d="M190.819 321.959H210.819V341.959H190.819z"></path>
      <path
        fill="#3f3d56"
        d="M245.819 330.959H406.81899999999996V332.959H245.819z"
      ></path>
      <path
        fill="#a0616a"
        d="M848.596 327.872l-25.548 20.137-54.135-19.026s-18.407-20.703-28.815-5.283c-3.565 5.281 19.784 15.038 21.986 15.944 5.507 2.267 58.259 31.207 63.428 27.41s34.253-23 34.253-23z"
        transform="translate(-216.272 -190.527)"
      ></path>
      <path
        fill="#2f2e41"
        d="M935.806 293.52c-24.213-9.048-47.247-13.328-65.922 0v-43.017a32.961 32.961 0 0132.96-32.96h.001a32.961 32.961 0 0132.961 32.96zM874.965 387.491l-1.979 13.85s-4.946 14.84 0 42.54l-79.638 217.15 58.863 16.817 29.184-118.715 41.056-90.52-3.958 102.887-20.775 109.81 61.336-2.472 17.874-188.294a166.793 166.793 0 00-14.945-86.825c-4.959-10.53-10.29-18.829-15.127-20.033-14.512-3.614-71.891 3.805-71.891 3.805z"
        transform="translate(-216.272 -190.527)"
      ></path>
      <path
        fill="#2f2e41"
        d="M800.768 659.546l-20.28 21.765s-39.078 20.28-11.377 22.754 37.098-8.41 37.098-8.41 34.625-2.473 34.13-7.42-2.967-21.269-2.967-21.269zM910.826 664.794v15.334s.495 6.43 0 7.915-7.914 19.785 16.818 15.828 14.84-19.29 14.84-19.29l-5.936-22.26z"
        transform="translate(-216.272 -190.527)"
      ></path>
      <circle cx="686.27" cy="65.265" r="24.856" fill="#a0616a"></circle>
      <path
        fill="#a0616a"
        d="M919.73 269.519s-3.462 32.646 0 38.582-35.12-5.936-35.12-5.936 8.41-22.259 5.441-31.163 29.68-1.483 29.68-1.483z"
        transform="translate(-216.272 -190.527)"
      ></path>
      <path
        fill="#e6e6e6"
        d="M939.516 363.501l7.914 21.765-73.702 8.409s-7.914-1.484-7.914-7.915-8.41-29.184-8.41-29.184-20.594-16.692-30.487-21.144c-3.586-1.613 1.779-.947 4.796-7.793.905-2.057 1.988-4.229 3.17-6.43a150.899 150.899 0 013.067-5.441 262.243 262.243 0 013.913-6.43c3.908-6.238 7.474-11.481 8.617-13.603 3.462-6.43 36.015-7.004 36.015-7.004 9.309 5.856 36.203 7.004 36.203 7.004s18.5 7.741 26.206 13.603c.56.42-9.388 54.163-9.388 54.163z"
        transform="translate(-216.272 -190.527)"
      ></path>
      <path
        fill="#2f2e41"
        d="M923.515 225.954v-.59l-.701.038a32.986 32.986 0 00-28.349-5.664 32.844 32.844 0 0112.652 6.536l-23.825 1.324-10.056 22.346h6.805l5.485-10.055v10.055h23.017l1.565-7.82 4.345 7.82h4.522c.028.558.071 1.112.071 1.676l-4.47 43.017 21.23-8.938V251.62a32.892 32.892 0 00-12.29-25.666z"
        transform="translate(-216.272 -190.527)"
      ></path>
      <path
        fill="#a0616a"
        d="M956.828 339.758l-1.483 39.077-3.958 75.681s5.442 32.647-8.409 32.152-3.462-38.582-3.462-38.582l-3.957-59.853 3.957-42.044z"
        transform="translate(-216.272 -190.527)"
      ></path>
      <path
        fill="#e6e6e6"
        d="M960.786 361.028c0 4.452-24.98 3.215-24.98 3.215l-13.108-68.508s18.5 7.741 26.206 13.603a23.23 23.23 0 014.388 6.43c.588 1.588 1.147 3.428 1.677 5.441a119.53 119.53 0 011.474 6.43 263.162 263.162 0 014.343 33.389z"
        transform="translate(-216.272 -190.527)"
      ></path>
      <path
        fill="#ccc"
        d="M279.418 519.95l-37.862-3.92a12.02 12.02 0 10-20.696 6.467 12.025 12.025 0 1020.563 5.56l37.995-3.494z"
        transform="translate(-216.272 -190.527)"
      ></path>
      <path
        fill="#00b775"
        d="M317.92 545.973c14.884 3.66 29.878-6.897 30.654-22.205q.032-.636.033-1.281t-.033-1.282c-.776-15.308-15.77-25.865-30.654-22.205l-50.495 12.416a11.07 11.07 0 100 22.14z"
        transform="translate(-216.272 -190.527)"
      ></path>
    </svg>
  );
}

export default React.memo(RestaurantIcon);