import styled from 'styled-components';
import { Container } from "../howitworks/styles";

export const WhiteStyledContainer = styled.div`
height:300px;
width:100%;
margin-top:-120px;
background: rgba(255,255,255,1)
`
export const StyledContainer = styled.div`
height:450px;
width:100%;

background: rgb(59,223,164);
background: linear-gradient(  90deg  ,#00b775 34%,rgba(0,157,100,0.5732668067226891))
`

export const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  a{
    text-decoration: none;
  }
`;

export const FlexMargin = styled(Flex)`
`;

export const MainText = styled.h1`
text-align: center;
`;

export const SubText = styled.p`
max-width: 70%;
`;

export const Bullet = styled.div`
border-radius: 12px;
font-weight: 700;
background-color:  ${props => props.theme.color.amarelo};
padding: 10px;
color: #333;
`;

export const FlexUsecases = styled(Flex)`
flex-direction: row;
flex-wrap: wrap;
margin: 100px 0;
`;

export const ContainerUsecases = styled(Container)`
max-width: 70%;
`;