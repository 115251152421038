import React from "react";

function RouteIcon({width, height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="ac509896-9cad-4c3c-91d3-d49a439698b6"
      width={width}
      height={height}
      data-name="Layer 1"
      viewBox="0 0 888 623.128"
    >
      <circle cx="787.191" cy="69.115" r="47.5" fill="#ff6584"></circle>
      <path
        fill="#3f3d56"
        d="M765.174 332.688l-37.216-73.073 37.33 63.949.338-26.156-25.574-52.822 25.669 45.907.609-47.038-27.804-42.276 27.895 34.879 2.032-85.865.01-.357v.363l-.32 108.947 30.695-44.103-30.607 53.341 1.212 52.587.125-1.39 35.395-46.526-35.436 51.448.153 6.577.06.104-.045.537 1.605 69.602q-3.474.145-7.018.118l.068-5.414-32.292-53.297 32.358 48.12z"
        transform="translate(-156 -138.436)"
      ></path>
      <path
        fill="#cbcbcb"
        d="M591.78 374.439l-37.217-73.073 37.33 63.948.339-26.155-25.575-52.823 25.67 45.908.608-47.038-27.803-42.276 27.894 34.878 2.032-85.864.01-.357.001.363-.32 108.947 30.694-44.103-30.607 53.34 1.212 52.588.126-1.39 35.394-46.527-35.436 51.45.153 6.576.06.104-.044.537 1.605 69.602q-3.475.145-7.018.118l.067-5.415-32.292-53.296 32.358 48.12z"
        transform="translate(-156 -138.436)"
      ></path>
      <path
        fill="#3f3d56"
        d="M914.4 321.288l-37.215-73.073 37.329 63.948.339-26.155-25.575-52.823 25.67 45.907.608-47.038-27.803-42.275 27.894 34.878 2.032-85.865.01-.356.001.362-.32 108.947 30.694-44.102-30.607 53.34 1.212 52.588.126-1.39 35.394-46.527-35.436 51.449.154 6.577.059.104-.044.537 1.605 69.602q-3.475.145-7.018.118l.067-5.415-32.292-53.296 32.359 48.12z"
        transform="translate(-156 -138.436)"
      ></path>
      <path
        fill="#f1f1f1"
        d="M273.742 572.39A597.577 597.577 0 01341.788 529c66.1-36.474 147.385-67.662 239.797-91.822q9.526-2.499 19.21-4.885 20.568-5.09 41.818-9.694 5.25-1.132 10.527-2.255 29.573-6.218 60.326-11.481c.411-.075.823-.14 1.244-.206q7.22-1.25 14.504-2.414c97.034-15.767 202.19-24.441 312.054-24.572.907-.01 1.824-.01 2.732-.01v379.903H156c0-68.878 42.837-133.471 117.742-189.175z"
        transform="translate(-156 -138.436)"
      ></path>
      <path
        fill="#00b775"
        d="M450.182 615.723c37.84-28.138 86.292-53.337 143.025-74.587q9.446-3.55 19.2-6.933c.908-.319 1.825-.637 2.742-.955q12.422-4.267 25.321-8.31 21.798-6.811 44.83-12.875 16.488-4.355 33.555-8.3 5.484-1.281 11.014-2.489 12.571-2.797 25.442-5.352c87.724-17.48 185.544-27.239 288.689-27.239v292.881H359.407c0-53.102 33.031-102.901 90.775-145.841z"
        transform="translate(-156 -138.436)"
      ></path>
      <path
        fill="#fff"
        d="M432.234 746.368l1.694.805a183.172 183.172 0 0120.942-33.546l-1.479-1.15a185.611 185.611 0 00-21.157 33.891zm48.2-63.273l1.282 1.366a335.313 335.313 0 0128.913-24.01c.562-.422 1.132-.843 1.703-1.264l-1.113-1.507c-.571.421-1.142.843-1.713 1.273a338.727 338.727 0 00-29.072 24.142zm64.031-47.507l.964 1.6c10.91-6.587 22.616-13.063 34.78-19.248l-.851-1.675c-12.202 6.214-23.945 12.717-34.893 19.323zm70.993-36.287l.74 1.712a780.106 780.106 0 0136.886-14.897l-.655-1.75a769.624 769.624 0 00-36.97 14.935zm74.615-28.081l.57 1.778a1143.14 1143.14 0 019.994-3.191 998.233 998.233 0 0128.1-8.319l-.506-1.806a971.664 971.664 0 00-28.155 8.347q-5.035 1.577-10.003 3.19zm88.453-24.385a938.8 938.8 0 00-11.724 2.77l.44 1.815c3.855-.927 7.794-1.862 11.715-2.77 3.715-.86 7.41-1.703 10.995-2.48a1074.29 1074.29 0 0116.122-3.462l-.374-1.834a1178.105 1178.105 0 00-16.15 3.462 832.797 832.797 0 00-11.024 2.499zm66.324-13.428l.328 1.844a1352.254 1352.254 0 0139.31-6.28l-.272-1.852a1394.347 1394.347 0 00-39.366 6.288zm78.9-11.444l.216 1.862a1528.01 1528.01 0 0139.609-4.07l-.169-1.862a1535.81 1535.81 0 00-39.656 4.07zm79.415-7.093l.112 1.872a1545.366 1545.366 0 0139.769-2.003l-.066-1.871a1633.349 1633.349 0 00-39.815 2.002z"
        transform="translate(-156 -138.436)"
      ></path>
      <path
        fill="#f1f1f1"
        d="M628.52 659.524C711.202 598.037 866.307 556.64 1044 556.64v204.923H565.004c0-37.148 23.112-71.994 63.517-102.04z"
        transform="translate(-156 -138.436)"
      ></path>
      <path
        d="M273.742 572.39l176.44 43.333 178.339 43.8L1044 761.565H156c0-68.878 42.837-133.471 117.742-189.175z"
        opacity="0.2"
        transform="translate(-156 -138.436)"
        style={{ isolation: "isolate" }}
      ></path>
      <path
        fill="#ffb7b7"
        d="M224.992 394.724L218.997 394.723 216.144 371.598 224.993 371.598 224.992 394.724z"
      ></path>
      <path
        fill="#2f2e41"
        d="M382.521 538.971H363.19v-.245a7.525 7.525 0 017.525-7.524h11.808z"
        transform="translate(-156 -138.436)"
      ></path>
      <path
        fill="#ffb7b7"
        d="M277.959 390.307L272.168 391.862 263.417 370.266 271.964 367.972 277.959 390.307z"
      ></path>
      <path
        fill="#2f2e41"
        d="M436.942 533.96l-18.671 5.011-.064-.236a7.525 7.525 0 015.317-9.218l11.404-3.06zM356.026 425.91c-3.466 4.456 7.425 41.584 7.92 42.08s-.495 3.465-.495 3.465a2.444 2.444 0 00.495 1.485c.495.495 0 .99 0 1.98s0 .99.99 2.97a7.877 7.877 0 01.495 4.455l6.436 37.624a2.47 2.47 0 00-1.485 2.475c0 1.98 9.9 2.97 13.366 2.475s-2.475-44.554-2.475-44.554 0-11.386.99-12.376 0-.99 0-2.97-1.485-4.95-.99-6.436.99-22.772.99-22.772 11.386 21.287 11.386 24.257 3.465 7.921 3.465 7.921l22.772 46.455s-1.485 2.97 0 5.446 7.921.495 14.357-1.98-2.476-6.436-2.476-6.436l-13.86-39.525a25.265 25.265 0 01.494-4.455c.495-2.475-1.485-1.485-2.97-2.475s-.99-.99 0-2.97-1.485-6.436-1.485-6.436l-6.436-36.633s-48.02 2.475-51.484 6.93z"
        transform="translate(-156 -138.436)"
      ></path>
      <circle cx="215.976" cy="185.368" r="12.871" fill="#ffb8b8"></circle>
      <path
        fill="#00b775"
        d="M367.189 340.598s-17.56 8.046-19.044 12.006 9.366 40.633 9.366 40.633.658 15.42-1.322 21.36-1.649 6.858-1.649 6.858v4.455c0 2.476 15.842.495 23.763-1.98s28.217-1.98 30.197-2.475-3.311-6.857-3.311-6.857c6.93-.495 0-15 0-15s-.709-46.954-2.689-53.39-20.311-7.61-20.311-7.61l-9.332 3.155z"
        transform="translate(-156 -138.436)"
      ></path>
      <path
        fill="#2f2e41"
        d="M387.568 320.416a12.535 12.535 0 00-1.752-9.333 13.944 13.944 0 00-9.433-6.127 18.356 18.356 0 00-11.031 1.868c-2.054 1.008-4.277 2.36-6.428 1.621l.632 1.134-1.6.019.585 1.326-1.528.218 1.182.709a27.096 27.096 0 00-3.143 9.07 6.24 6.24 0 00.282 3.783c.579 1.13 2.07 1.79 3.025 1.01.599-.489.803-1.346 1.223-2.017s1.385-1.14 1.886-.536l1.291-2.581 1.034.841a26.885 26.885 0 018.473-5.046 2.805 2.805 0 012.46-.067c1.154.74.987 2.63 1.786 3.792 1.3 1.889 4.5 1.105 5.73 3.046a8.57 8.57 0 01.725 2.437c.22.824.75 1.69 1.545 1.744 1.677-1.73 2.317-4.127 3.056-6.91z"
        transform="translate(-156 -138.436)"
      ></path>
      <path
        fill="#ffb8b8"
        d="M418.635 422.628a5.43 5.43 0 01-.848-8.283l-8.655-17.247 9.958 1.18 6.692 16.196a5.46 5.46 0 01-7.147 8.154z"
        transform="translate(-156 -138.436)"
      ></path>
      <path
        fill="#00b775"
        d="M411.505 410.249l-10.09-32.663-11.517-24.97a7.662 7.662 0 015.492-10.73 7.703 7.703 0 018.696 4.987l21.001 59.943a2.43 2.43 0 01-1.786 3.18l-8.968 1.912a2.484 2.484 0 01-.517.055 2.416 2.416 0 01-2.311-1.714z"
        transform="translate(-156 -138.436)"
      ></path>
      <path
        fill="#ffb8b8"
        d="M349.158 433.907a5.43 5.43 0 00-.98-8.268l4.685-18.72-9.461 3.324-2.998 17.266a5.46 5.46 0 008.754 6.398z"
        transform="translate(-156 -138.436)"
      ></path>
      <path
        fill="#00b775"
        d="M351.534 422.448a2.484 2.484 0 01-.517.06l-9.168.09a2.43 2.43 0 01-2.437-2.714l7.418-63.08a7.703 7.703 0 017.399-6.764 7.662 7.662 0 017.7 9.272l-5.792 26.882-2.721 34.077a2.416 2.416 0 01-1.882 2.177z"
        transform="translate(-156 -138.436)"
      ></path>
    </svg>
  );
}

export default React.memo(RouteIcon);
