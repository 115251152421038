import * as React from "react"
import Layout from "../common/layout"
import UseCasesPage from "../views/usecases"

const UseCases = () => {
  return (
    <Layout>
      <title>Usecases - APISheet.co - Give your spreadsheets suporpower!</title>
        <UseCasesPage />
    </Layout>

  )
}

export default UseCases;
