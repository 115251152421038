import * as React from "react";
import AppWireframeIcon from "../../images/vectors/appwireframe";
import CollaborationIcon from "../../images/vectors/collaboration";
import RestaurantIcon from "../../images/vectors/restaurant";
import RouteIcon from "../../images/vectors/route";
import TodoListIcon from "../../images/vectors/todolist";
import WebsiteDevIcon from "../../images/vectors/websitedev";
import { OutlinedGreenButton } from "../../styles/globalstyles";
import UseCaseCardComponent from "../usecasecard";
import { Flex, FlexMargin, MainText, StyledContainer, FlexUsecases, ContainerUsecases, Bullet } from "./styles";


const MainUseCasesComponent = () => {
  return <React.Fragment>
    <StyledContainer>
      <div style={{ padding: '180px 0 0 0', }}>
        <FlexMargin >
          <MainText>How can APISheet<span style={{ color: '#e8ce95' }}>.</span>co help me?</MainText>
        </FlexMargin>
        <Flex><Bullet>
          use cases</Bullet></Flex>
      </div>
    </StyledContainer>


    <ContainerUsecases>
      <FlexUsecases>
        <UseCaseCardComponent
          content={<RestaurantIcon width={300} />}
          title={'Restaurant Menu'}
          subtext={'Keep your menu updated'}
          maintext={'Use a Google Spreadsheet as the database, and easily update your restaurant\'s online menu'} />
        <UseCaseCardComponent
          content={<CollaborationIcon width={300} />}
          title={'Non-Tech People'}
          subtext={'Easily collaborate with non-tech people'}
          maintext={'Everyone, from Finances to HR, knows how to deal with spreadsheets; Use this to your advantage'} />
      </FlexUsecases>
    </ContainerUsecases>

    <ContainerUsecases>
      <FlexUsecases>
        <UseCaseCardComponent
          content={<AppWireframeIcon width={280} />}
          title={'Interactive Prototypes'}
          subtext={'Fast prototyping without backend'}
          maintext={'Add real functionalities to your prototypes using a spreadsheet as API'} />
        <UseCaseCardComponent
          content={<RouteIcon width={350} />}
          title={'Route Timetable'}
          subtext={'Inform the where\'s and when\'s of a route'}
          maintext={'Edit and share stops of a route, updating only your spreadsheet'} />
      </FlexUsecases>
    </ContainerUsecases>
    <ContainerUsecases>
      <FlexUsecases>
        <UseCaseCardComponent
          content={<TodoListIcon width={300} />}
          title={'Task Management'}
          subtext={'Set up tasks and todos'}
          maintext={'Distribute tasks to your team and sync their status in a spreadsheet; Collaboration made easy'} />
        <UseCaseCardComponent
          title={'#nocode Website'}
          content={<WebsiteDevIcon width={300} />}
          subtext={'Make your website interactive'}
          maintext={'Deliver websites with dynamic content without worrying about backend code, database and such'} />
      </FlexUsecases>
    </ContainerUsecases>

    <Flex style={{ justifyContent: 'space-evenly', margin: '50px' }}>
      <a href="https://console.apisheet.co" style={{textDecoration:`none`}}>
        <OutlinedGreenButton>Start free</OutlinedGreenButton>
      </a>
    </Flex>
  </React.Fragment>
}

export default MainUseCasesComponent;
