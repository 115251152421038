import React from "react";

function DownArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 100 100"
      width="30"
      xmlSpace="preserve"
    >
      <path
        fill="#010101"
        d="M23.1 34.1L51.5 61.7 80 34.1 81.5 35 51.5 64.1 21.5 35 23.1 34.1z"
      ></path>
    </svg>
  );
}

export default React.memo(DownArrowIcon);
