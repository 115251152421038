import { animated } from 'react-spring';
import styled from 'styled-components';

export const SpanMainText = styled.span`
  font-family: ${props => props.theme.font.semibold};
  color: ${props => props.theme.color.black.light};
  font-size: 28px;
  padding: 40px 20px;
  text-align: start;
`;

export const CardWrapper = styled(animated.div)`
  background: ${props => props.theme.color.white.regular};
  color: ${props => props.theme.color.black.regular};
  flex:1;
  text-align: center;
  padding: 5px 10px 10px 10px;
  min-width: 350px;
  margin: 25px 10px;

  color: ${props => props.theme.color.black.lightest};

  div h4{
    cursor:pointer;
    font-family: ${props => props.theme.font.semibold};
    text-decoration:  ${props => props.theme.color.amarelo} underline;
    text-decoration-thickness: 2px;
    &:hover{
      text-decoration-thickness:4px;
    }
  }

  ${({ clicked }) => clicked && `
  -webkit-box-shadow: 0px 0px 25px -16px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 25px -16px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 25px -16px rgba(0,0,0,0.75);
  `}
`;

export const SpanSubText=styled.p`
  font-weight:600;
  font-size: 14px;

`;