import * as React from "react";
import { animated, config, useSpring } from 'react-spring';
import DownArrow from '../../images/vectors/downarrow';
import { CardWrapper, SpanMainText, SpanSubText } from "./styles";


const UseCaseCardComponent = ({ title, subtext, maintext, content }) => {
  const [clicked, setClicked] = React.useState(false);
  const cardWrapperProps = useSpring({
    width: '400px',
    height: `${clicked ? '600px' : '147px'}`,
    from: { height: '147px' },
    config: config.stiff
  })
  const mainTextProps = useSpring({
    config: config.slow,
    delay: 300,
    color: `${clicked ? '#000' : '#fff'}`,
    display: 'flex',
    from: {
      color: '#fff',
    }
  });

  const titleProps = useSpring({
    config: config.slow,
    backgroundColor: `${clicked ? '#122121' : '#fff'}`,
    height: '50px',
    width: '100%',
    borderRadius: '4px',
    color: `${clicked ? '#fff' : '#122121'}`,
    from: {
      backgroundColor: '#fff',
      color: '#fff',
      height: '0px',
    }
  });
  const iconProps = useSpring({
    marginTop: clicked ? 0 : 100,
    opacity: clicked ? 1 : 0,
    config: config.molasses,
    delay: 100,
  });
  const arrowProps = useSpring({
    marginTop: clicked ? 10 : -10,
    opacity: clicked ? 0 : 1,
    config: config.stiff,
  });

  return <React.Fragment>
    <CardWrapper
      style={cardWrapperProps}
      clicked={clicked ? 1 : 0}>
      <animated.div style={titleProps}>
        <h4 onClick={() => { setClicked(!clicked); }} style={{ lineHeight: '50px' }}>
          {title} <animated.div style={arrowProps}><DownArrow /></animated.div>
        </h4>
      </animated.div>
      <div>
        <SpanSubText>{subtext}</SpanSubText>
      </div>
      {clicked && <animated.div style={mainTextProps}>
        <SpanMainText>{maintext}</SpanMainText>
      </animated.div>}
      {clicked && <animated.div style={iconProps}>{content}</animated.div>}
    </CardWrapper>
  </React.Fragment>
}

export default UseCaseCardComponent;
